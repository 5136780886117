import { breakpoints, spacing } from '@hp/theme';
import styled, { css } from 'styled-components';

/** adds  extra padding on large displays*/
export const ExtraPaddings = styled.div<{
  vertical?: boolean;
}>`
  flex-grow: 1;
  padding-left: ${spacing.m};
  padding-right: ${spacing.m};
  
  @media (min-width: ${breakpoints.minDesktop}) {
    padding-right: ${spacing.unit(24)};
    padding-left: ${spacing.unit(24)};
    
    ${({ vertical }) =>
      vertical
        ? css`
            padding-top: ${spacing.unit(0)};
            padding-bottom: ${spacing.unit(24)};
          `
        : ''}
`;
