import { spacing } from '@hp/theme';
import React from 'react';
import styled from 'styled-components';

import { Typography, TypographyType } from './Typography';

type NoteItemProps = {
  className?: string;
  icon?: JSX.Element;
  noMargin?: boolean;
};

const RowStyled = styled.div<Pick<NoteItemProps, 'noMargin'>>`
  display: flex;
  align-items: center;
  margin: ${({ noMargin }) => (noMargin ? '0' : `${spacing.m} 0`)};
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: ${spacing.s};
  margin-bottom: 2px;
`;

const TypographyStyled = styled(Typography)`
  margin-top: 2px;
`;

export const NoteItem: React.FC<NoteItemProps> = ({
  icon,
  noMargin = false,
  children,
  ...props
}) => (
  <RowStyled noMargin={noMargin} {...props}>
    {icon && <IconWrapper>{icon}</IconWrapper>}
    <TypographyStyled type={TypographyType.BodyMicroLight}>
      {children}
    </TypographyStyled>
  </RowStyled>
);
