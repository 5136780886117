import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const ChevronNavBigIcon: FC<Props> = ({
  className,
  color = colors.black,
}) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17 2L7 12L17 22" stroke={color} strokeLinecap="round" />
  </svg>
);
