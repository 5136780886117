import { useState } from 'react';

import { RoutesType } from '../types';

export const useHistory = () => {
  const [history, setHistory] = useState<string[]>([]);

  const push = (route: keyof RoutesType) => {
    setHistory((prev) => {
      const newHistory = [...prev, route];
      //@ts-ignore - for dev
      window.xHistory = newHistory;
      return newHistory;
    });
  };

  const back = () => {
    //todo:
    console.log(history);
  };

  return { push, back };
};
