import React, { FC, ReactNode } from 'react';

type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: (children: ReactNode) => ReactNode;
  elseWrapper?: (children: ReactNode) => ReactNode;
};

export const ConditionalWrapper: FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  elseWrapper,
  children,
}) => {
  return (
    <>
      {condition
        ? wrapper(children)
        : elseWrapper
        ? elseWrapper(children)
        : children}
    </>
  );
};
