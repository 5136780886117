import { colors } from '@hp/theme';
import styled, { css } from 'styled-components';

type LinkElementProps = {
  disabled: boolean;
  textAlign: 'left' | 'center' | 'right';
  noDecoration?: boolean;
};

export const LinkElement = styled.a<LinkElementProps>`
  color: ${colors.black};
  text-decoration: ${({ noDecoration }) =>
    noDecoration ? 'none' : 'underline'};
  text-align: ${({ textAlign }) => textAlign};

  :hover {
    color: ${colors.red_main};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      color: ${colors.gray_mid};
    `}
`;
