import React, { FC } from 'react';
import styled from 'styled-components';

import { DPDLogo } from './logos';
import { VerticalCenter } from './VerticalCenter';

type LoaderProps = {
  height?: string;
};

export const AnimatedLogo = styled(DPDLogo)`
  width: 50px;
  height: 50px;

  @keyframes animationFrames {
    0% {
      transform: scale(1);
      opacity: 0.4;
    }
    100% {
      transform: scale(1.2);
      opacity: 1;
    }
  }
  animation: animationFrames 1s linear 0s alternate infinite none running;
`;

export const Loading: FC<LoaderProps> = ({ height = '60vh' }) => (
  <VerticalCenter height={height}>
    <AnimatedLogo withoutText />
  </VerticalCenter>
);
