import { breakpoints } from '@hp/theme';
import React, { FC } from 'react';
import styled from 'styled-components';

type NowrapProps = {
  className?: string;
  withBreak?: boolean;
  onlyMobile?: boolean;
};

const StyledSpan = styled.span<NowrapProps>`
  white-space: nowrap;

  @media (min-width: ${breakpoints.minDesktop}) {
    white-space: ${({ onlyMobile }) => onlyMobile && 'initial'};
  }
`;

export const Nowrap: FC<NowrapProps> = ({ withBreak, children, ...props }) => {
  return (
    <>
      <StyledSpan {...props}>{children}</StyledSpan>
      {withBreak && <br />}
    </>
  );
};
