import { spacing } from '@hp/theme';
import styled, { css } from 'styled-components';

export type CenteredProps = {
  spacingBetween?: Exclude<keyof typeof spacing, 'unit' | 'zero'>;
};

export const Centered = styled.div<CenteredProps>`
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${({ spacingBetween }) =>
    spacingBetween
      ? css`
          > * {
            margin: ${spacing[spacingBetween]};
            &:first-child {
              margin-top: 0;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        `
      : ''}
`;

export const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-top: 50px;
  flex-grow: 1;
`;
