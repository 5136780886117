import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const DeliveredIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M59.001 54.003H19.001V46.911C19.001 46.358 19.449 45.911 20.001 45.911C20.553 45.911 21.001 46.358 21.001 46.911V52.003H57.001V15.998H21.001V24.405C21.001 24.957 20.553 25.405 20.001 25.405C19.449 25.405 19.001 24.957 19.001 24.405V13.998H59.001V54.003Z"
      fill="#DC0032"
    />
    <path
      d="M53.006 38H33.006C32.454 38 32.006 37.553 32.006 37C32.006 36.447 32.454 36 33.006 36H53.006C53.559 36 54.006 36.447 54.006 37C54.006 37.553 53.559 38 53.006 38Z"
      fill="#414042"
    />
    <path
      d="M49.005 33H33.005C32.453 33 32.005 32.552 32.005 32C32.005 31.448 32.453 31 33.005 31H49.005C49.558 31 50.005 31.448 50.005 32C50.005 32.552 49.558 33 49.005 33Z"
      fill="#414042"
    />
    <path
      d="M49.005 43.001H33.005C32.453 43.001 32.005 42.554 32.005 42.001C32.005 41.448 32.453 41.001 33.005 41.001H49.005C49.558 41.001 50.005 41.448 50.005 42.001C50.005 42.554 49.558 43.001 49.005 43.001Z"
      fill="#414042"
    />
    <path
      d="M28.003 32C27.68 32 27.363 31.844 27.17 31.555C26.864 31.096 26.988 30.474 27.447 30.168L39.448 22.167C39.908 21.859 40.528 21.985 40.835 22.444C41.142 22.903 41.018 23.525 40.558 23.831L28.557 31.832C28.387 31.945 28.194 32 28.003 32Z"
      fill="#414042"
    />
    <path
      d="M40.005 23.999C39.682 23.999 39.364 23.842 39.171 23.553C38.865 23.094 38.99 22.472 39.45 22.167L39.574 22.084C40.223 21.65 40.22 21 39.998 20.536C39.775 20.068 39.265 19.651 38.514 19.887L38.299 19.954C37.77 20.119 37.211 19.822 37.048 19.295C36.884 18.768 37.18 18.208 37.707 18.044L37.919 17.978C39.489 17.488 41.091 18.184 41.803 19.676C42.512 21.162 42.052 22.837 40.683 23.75L40.558 23.833C40.388 23.944 40.195 23.999 40.005 23.999Z"
      fill="#414042"
    />
    <path
      d="M44.004 48.002H21.538C13.921 48.002 9 42.898 9 35C9 26.775 17.5 24.107 22.066 22.675L22.179 22.639C26.616 21.246 37.616 18.069 37.726 18.037C38.257 17.885 38.811 18.19 38.964 18.721C39.117 19.252 38.811 19.806 38.28 19.959C38.17 19.991 27.192 23.162 22.777 24.547L22.664 24.583C18.302 25.952 11 28.244 11 35C11 43.111 16.443 46.002 21.538 46.002H44.004C44.557 46.002 45.004 46.449 45.004 47.002C45.004 47.555 44.557 48.002 44.004 48.002Z"
      fill="#414042"
    />
  </svg>
);
