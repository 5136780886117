import { useRouter as useNextRouter } from 'next/router';

import { QueryParams } from '../queryParams';
import { routes } from '../routes';
import { RouteNameType, RouteQueryType } from '../types';

export const useRouter = () => {
  const nextRouter = useNextRouter();
  const getUrl = <T extends RouteNameType>(
    to: RouteNameType,
    query?: RouteQueryType<T>,
  ) => {
    const rawHref = routes[to].href;
    //@ts-ignore
    const href = typeof rawHref === 'function' ? rawHref(query ?? {}) : rawHref;
    return href;
  };
  const getQueryStr = (
    params?: Partial<Record<QueryParams, string | string[]>>,
  ) => {
    return Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
  };

  return {
    getUrl,
    getGlobalUrl: <T extends RouteNameType>(
      to: RouteNameType,
      params?: RouteQueryType<T>,
    ) =>
      `${document.location.origin}${getUrl(to)}${
        params ? `?${getQueryStr(params)}` : ''
      }`,
    push: async <T extends RouteNameType>(
      to: T,
      query?: RouteQueryType<T>,
      asHrefParam?: any,
    ) => {
      if (!asHrefParam) {
        await nextRouter.push(getUrl<T>(to, query));
      } else {
        await nextRouter.push(
          getUrl<T>(to), //todo: redundant check "!!asHrefParam" ?
          !!asHrefParam ? routes[to].asHref(asHrefParam) : undefined,
        );
      }
    },
    replace: async <T extends RouteNameType>(
      to: T,
      query?: RouteQueryType<T>,
      asHrefParam?: any,
    ) => {
      if (!asHrefParam) {
        await nextRouter.replace(getUrl<T>(to, query));
      } else {
        await nextRouter.replace(
          getUrl(to),
          !!asHrefParam ? routes[to].asHref(asHrefParam) : undefined,
        );
      }
    },
    query: nextRouter?.query,
    pathname: nextRouter?.pathname,
    current: nextRouter
      ? Object.entries(routes)
          .filter(([_, value]) => value.href === nextRouter.route)
          ?.map(([_, value]) => value)?.[0]
      : undefined,
  };
};
