import { spacing } from '@hp/theme';
import React from 'react';
import styled from 'styled-components';

import { Button, ButtonProps, ButtonType } from './Button';

type NoteButtonProps = {
  iconWidth?: string;
} & Omit<ButtonProps, 'type' | 'buttonType'>;

const IconWrapper = styled.div<Pick<NoteButtonProps, 'iconWidth'>>`
  width: ${({ iconWidth }) => (iconWidth ? iconWidth + 'px' : '20px')};
  height: 20px;
  margin-right: ${spacing.s};
`;

const ButtonStyled = styled(Button)`
  padding-left: 28px;
  text-transform: none;
  text-decoration: underline;
`;

export const NoteButton: React.FC<NoteButtonProps> = ({
  icon,
  iconWidth,
  children,
  ...props
}) => (
  <ButtonStyled buttonType={ButtonType.TEXT} type="button" {...props}>
    <IconWrapper iconWidth={iconWidth}>{icon}</IconWrapper>
    {children}
  </ButtonStyled>
);
