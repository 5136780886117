import { breakpoints, elements } from '@hp/theme';
import styled from 'styled-components';

import { LocationIcon } from '../icons';

export const Wrapper = styled.div<{ height: number; desktopHeight: number }>`
  width: 100%;
  height: ${({ height }) => height}px;
  position: relative;
  overflow: hidden;

  @media (min-width: ${breakpoints.minDesktop}) {
    height: ${({ desktopHeight }) =>
      desktopHeight
        ? `${desktopHeight}px`
        : `calc(100vh - ${elements.navigationHeight})`};
  }
`;

export const LocationIconStyled = styled(LocationIcon)`
  position: absolute;
  top: -50px;
  left: -25px;
  width: 50px;
  height: 50px;
`;
