import { colors } from '@hp/theme';
import React, { FC, SVGAttributes } from 'react';

type Props = {
  className?: string;
  color?: string;
  width?: SVGAttributes<SVGElement>['width'];
};

export const ConfirmIcon: FC<Props> = ({
  className,
  color = colors.white,
  width = 32,
}) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27 13.9973V21.6639C26.9995 22.1316 26.8761 22.5909 26.6421 22.9957C26.408 23.4006 26.0717 23.7368 25.6667 23.9706L16.3333 29.3039C15.9279 29.538 15.4681 29.6612 15 29.6612C14.5319 29.6612 14.0721 29.538 13.6667 29.3039L4.33333 23.9706C3.92835 23.7368 3.59197 23.4006 3.35795 22.9957C3.12392 22.5909 3.00048 22.1316 3 21.6639V10.9973C3.00048 10.5296 3.12392 10.0703 3.35795 9.66548C3.59197 9.26062 3.92835 8.92442 4.33333 8.6906L13.6667 3.35727C14.0721 3.12322 14.5319 3 15 3C15.4681 3 15.9279 3.12322 16.3333 3.35727L25.6667 8.6906"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 15.5L14 22.5L29.5 7"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
