import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const ExclamationTriangleIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.6927 54.7031H13.3073C12.2862 54.7031 11.3723 54.1751 10.8612 53.2913C10.3502 52.4075 10.3511 51.3506 10.8612 50.4668L31.5539 14.6259C32.064 13.7421 32.9789 13.2141 34 13.2141C35.0212 13.2141 35.9351 13.7421 36.4462 14.6259L57.1389 50.4668C57.649 51.3515 57.649 52.4085 57.1379 53.2913C56.6278 54.176 55.713 54.7031 54.6927 54.7031ZM34 15.0965C33.8363 15.0965 33.4297 15.1426 33.185 15.5671L12.4913 51.408C12.2457 51.8325 12.4094 52.2089 12.4913 52.3501C12.5732 52.4913 12.816 52.8207 13.3073 52.8207H54.6927C55.1831 52.8207 55.4259 52.4913 55.5078 52.3501C55.5897 52.2089 55.7534 51.8334 55.5078 51.408L34.8151 15.5671C34.5704 15.1426 34.1638 15.0965 34 15.0965Z"
      fill="#414042"
    />
    <path
      d="M34 47.312C34.5198 47.312 34.9412 46.8906 34.9412 46.3708C34.9412 45.851 34.5198 45.4297 34 45.4297C33.4802 45.4297 33.0588 45.851 33.0588 46.3708C33.0588 46.8906 33.4802 47.312 34 47.312Z"
      fill="#DC0032"
    />
    <path
      d="M34 43.5473C33.4805 43.5473 33.0588 43.1266 33.0588 42.6061V29.2536C33.0588 28.7341 33.4805 28.3125 34 28.3125C34.5195 28.3125 34.9412 28.7341 34.9412 29.2536V42.6061C34.9412 43.1256 34.5195 43.5473 34 43.5473Z"
      fill="#DC0032"
    />
  </svg>
);
