import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const PaymentCardIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 10.5L19 10.5"
      stroke="#DC0032"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 16C15.3284 16 16 15.3284 16 14.5C16 13.6716 15.3284 13 14.5 13C13.6716 13 13 13.6716 13 14.5C13 15.3284 13.6716 16 14.5 16Z"
      fill="#DC0032"
    />
    <path
      d="M16.5 16C17.3284 16 18 15.3284 18 14.5C18 13.6716 17.3284 13 16.5 13C15.6716 13 15 13.6716 15 14.5C15 15.3284 15.6716 16 16.5 16Z"
      fill="#414042"
    />
    <rect
      x="3.5"
      y="8.5"
      width="16"
      height="10"
      rx="1"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.2941 9.97074L1.25882 6.10704C1.11588 5.57357 1.43246 5.02524 1.96593 4.88229L15.4889 1.25883C16.0224 1.11589 16.5707 1.43247 16.7136 1.96593L17.7489 5.82964L18.0724 7.03704"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="12"
      y="4.79858"
      width="3.08496"
      height="1.9281"
      rx="0.964051"
      transform="rotate(-15 12 4.79858)"
      fill="#414042"
    />
  </svg>
);
