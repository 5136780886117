import { config as appConfig, Environment } from '@hp/config';

import { RoutesType } from './types';

const isProd = appConfig.app.environment === Environment.PRODUCTION;
const noSsr = true;

export const routes: RoutesType = {
  landing: {
    href: ({ id }) => (id ? `/${id}` : '/'),
    noSsr,
  },
  detail: {
    href: ({ id }) => `/${id}/detail`,
    noSsr,
  },
  adminMessages: {
    href: '/admin/messages',
    requirements: () => (isProd ? 'landing' : null),
  },
  adminFeatures: {
    href: '/admin/features',
    requirements: () => (isProd ? 'landing' : null),
  },
  error404: {
    href: '/404',
  },
  error500: {
    href: '/500',
  },
};

export enum UtmQueryParams {
  utm_source = 'utm_source',
  utm_medium = 'utm_medium',
  utm_campaign = 'utm_campaign',
  utm_content = 'utm_content',
}
