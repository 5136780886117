import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const ParcelColorsIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M66 56H2V15C2 13.346 3.346 12 5 12H63C64.654 12 66 13.346 66 15V56ZM4 54H64V15C64 14.449 63.552 14 63 14H5C4.449 14 4 14.449 4 15V54Z"
      fill="#414042"
    />
    <path d="M38 36H30V24H38V36ZM32 34H36V26H32V34Z" fill="#DC0032" />
    <path
      d="M27 56C26.448 56 26 55.553 26 55V13C26 12.448 26.448 12 27 12C27.552 12 28 12.448 28 13V55C28 55.553 27.552 56 27 56Z"
      fill="#414042"
    />
    <path
      d="M41 56C40.447 56 40 55.553 40 55V13C40 12.448 40.447 12 41 12C41.553 12 42 12.448 42 13V55C42 55.553 41.553 56 41 56Z"
      fill="#414042"
    />
    <path d="M28 42H2V26H28V42ZM4 40H26V28H4V40Z" fill="#414042" />
    <path d="M66 42H40V26H66V42ZM42 40H64V28H42V40Z" fill="#414042" />
    <path
      d="M37 42H31C30.448 42 30 41.553 30 41C30 40.447 30.448 40 31 40H37C37.553 40 38 40.447 38 41C38 41.553 37.553 42 37 42Z"
      fill="#DC0032"
    />
  </svg>
);
