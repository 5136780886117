import { breakpoints, elements } from '@hp/theme';
import React from 'react';
import styled from 'styled-components';

type Props = {
  bodyWidth?: string;
  className?: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Body = styled.div<Required<Pick<Props, 'bodyWidth'>>>`
  padding: 0;
  width: 100%;
  display: flex;
  flex: 1 0 auto; // align footer to bottom
  justify-content: start;
  flex-direction: column;

  @media (min-width: ${breakpoints.minDesktop}) {
    margin: 0 auto;
    width: ${({ bodyWidth }) => bodyWidth};
    max-width: 100%;
  }
`;

/** base layout container (without colors, shadows.., just layout)*/
export const HorizontalLayout: React.FC<Props> = ({
  children,
  bodyWidth = elements.desktopContainerWidth,
  className,
}) => {
  return (
    <Container className={className}>
      <Body bodyWidth={bodyWidth}>{children}</Body>
    </Container>
  );
};
