import { breakpoints, elements, spacing } from '@hp/theme';
import styled, { css } from 'styled-components';

type FullHeightLayoutProps = {
  exceptDesktopFooter?: boolean;
  exceptMobileFooter?: boolean;
};

/** consumes all vertical space */
export const FullHeightLayout = styled.div<FullHeightLayoutProps>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* align footer to bottom */
  flex: 1 0 auto;
  flex-direction: column;

  @media (max-width: ${breakpoints.maxMobile}) {
    ${({ exceptMobileFooter }) =>
      exceptMobileFooter &&
      css`
        margin-bottom: calc(${elements.buttonHeightBottom} + ${spacing.m});

        @media (min-width: ${breakpoints.minDesktop}) {
          margin-bottom: calc(${elements.buttonHeightBottom} + 3 * (24px));
        }
      `};
  }

  @media (min-width: ${breakpoints.minDesktop}) {
    ${({ exceptDesktopFooter }) =>
      exceptDesktopFooter &&
      css`
        margin-bottom: calc(${elements.buttonHeightBottom} + ${spacing.m});

        @media (min-width: ${breakpoints.minDesktop}) {
          margin-bottom: calc(${elements.buttonHeightBottom} + 3 * (24px));
        }
      `};
  }
`;
