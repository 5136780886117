import { colors, spacing } from '@hp/theme';
import styled from 'styled-components';

type HrProps = {
  noMargin?: boolean;
};

export const Hr = styled.div<HrProps>`
  border-top: 1px solid ${colors.gray_light};
  width: 100%;
  height: 1px;
  margin: 0 0 ${({ noMargin }) => (noMargin ? '0' : spacing.m)} 0;
  padding: 0;
`;
