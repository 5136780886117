import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const MobileChatIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33337 2.08337C8.33337 1.75185 8.46507 1.43391 8.69949 1.19949C8.93391 0.96507 9.25185 0.833374 9.58337 0.833374H17.9167C18.2482 0.833374 18.5662 0.96507 18.8006 1.19949C19.035 1.43391 19.1667 1.75185 19.1667 2.08337V7.08337C19.1667 7.41489 19.035 7.73284 18.8006 7.96726C18.5662 8.20168 18.2482 8.33337 17.9167 8.33337H11.25L8.33337 10.4167V2.08337Z"
      stroke="#DC0032"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3334 5.83337H10.8334"
      stroke="#DC0032"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6667 3.33337H10.8334"
      stroke="#DC0032"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25004 2.5H3.75004C3.30801 2.5 2.88409 2.67559 2.57153 2.98816C2.25897 3.30072 2.08337 3.72464 2.08337 4.16667V17.5C2.08337 17.942 2.25897 18.3659 2.57153 18.6785C2.88409 18.9911 3.30801 19.1667 3.75004 19.1667H11.6667C12.1087 19.1667 12.5327 18.9911 12.8452 18.6785C13.1578 18.3659 13.3334 17.942 13.3334 17.5V10.4167"
      stroke="#414042"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.08337 5H6.25004"
      stroke="#414042"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.08337 16.25H13.3334"
      stroke="#414042"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
