import { colors, spacing, typography } from '@hp/theme';
import React, { FC } from 'react';
import styled from 'styled-components';

type H1Props = {
  id?: string;
  className?: string;
  color?: string;
  fontWeight?: keyof typeof typography.fontWeight;
  marginSize?: Extract<keyof typeof spacing, 'm' | 'l'>;
  textAlign?: 'center' | 'left' | 'right';
};

const H1Styled = styled.h1<H1Props>`
  font-size: ${typography.fontSize.h1};
  line-height: ${typography.lineHeight.h1};
  font-weight: ${({ fontWeight }) =>
    fontWeight && typography.fontWeight[fontWeight]};
  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
  margin: ${({ marginSize }) => marginSize && spacing[marginSize]} 0;
`;

export const H1: FC<H1Props> = ({
  id,
  className,
  color = colors.black,
  fontWeight = typography.fontWeight.normal,
  marginSize = 'm',
  textAlign = 'left',
  children,
}) => {
  const commonProps = { className, color, marginSize, textAlign };

  return (
    <H1Styled
      id={id}
      fontWeight={fontWeight as keyof typeof typography.fontWeight}
      {...commonProps}
    >
      {children}
    </H1Styled>
  );
};
