import React, { FC } from 'react';
import styled from 'styled-components';

type VerticalCenterProps = {
  height?: string;
};

const Wrapper = styled.div<VerticalCenterProps>`
  text-align: center;
  display: table;
  height: ${({ height }) => height};
  width: 100%;
`;

const Middle = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

export const VerticalCenter: FC<VerticalCenterProps> = ({
  children,
  height = '100%',
}) => (
  <Wrapper height={height}>
    <Middle>{children}</Middle>
  </Wrapper>
);
