import { breakpoints } from '@hp/theme';
import { UserAgent } from '@quentin-sommer/react-useragent';
import React, { FC } from 'react';
import Media from 'react-media';

type Props = {
  accountHomepage?: boolean;
};

export const MediaQueryJS: FC<Props> = ({ children, accountHomepage }) => (
  <UserAgent mobile>
    {(isMobile) => (
      <Media
        query={{
          maxWidth: accountHomepage
            ? breakpoints.bigDesktop
            : breakpoints.maxMobile,
        }}
        defaultMatches={isMobile}
      >
        {children}
      </Media>
    )}
  </UserAgent>
);
