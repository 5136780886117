import { t } from '@lingui/macro';

import { MetaType } from './types';

export const meta: MetaType = {
  default: {
    title: t('meta.title.default')`Online dobírka | DPD`,
    tags: {
      description: t(
        'meta.description.default',
      )`Zaplaťte vaši dobírku pohodlne online`,
      keywords: t(
        'meta.keywords.default',
      )`Balík, dobírka, přeprava po ČR, kurýr`,
    },
  },
};
