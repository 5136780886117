import React, { FC } from 'react';
import styled, { css } from 'styled-components';

type UpperCaseProps = {
  className?: string;
  mode?: 'capitalize' | 'capitalizeFirstLetter' | 'uppercase';
};

const Styled = styled.span<Pick<UpperCaseProps, 'mode'>>`
  ${({ mode = 'uppercase' }) =>
    mode === 'capitalizeFirstLetter'
      ? css`
          text-transform: lowercase;

          :first-letter {
            text-transform: uppercase;
          }
        `
      : css`
          text-transform: ${mode};
        `};
`;

export const UpperCase: FC<UpperCaseProps> = ({ children, ...props }) => (
  <Styled {...props}>{children}</Styled>
);
