import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
  zoom?: number;
};

export const ChevronIcon: FC<Props> = ({
  className,
  color = colors.black,
  zoom = 1,
}) => (
  <svg
    className={className}
    width={zoom * 12}
    height={zoom * 22}
    viewBox="0 0 12 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L11 11L1 21" stroke={color} strokeLinecap="round" />
  </svg>
);
