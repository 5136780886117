import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const ChevronFilledIcon: FC<Props> = ({
  className,
  color = colors.white,
}) => (
  <svg
    className={className}
    width="10"
    height="5"
    viewBox="0 0 10 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0L5 5L10 0H0Z"
      fill={color}
    />
  </svg>
);
