import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const ReturnIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.283 32C24.027 32 23.771 31.902 23.576 31.707L14.869 23L23.576 14.293C23.967 13.902 24.599 13.902 24.99 14.293C25.381 14.684 25.381 15.316 24.99 15.707L17.697 23L24.99 30.293C25.381 30.684 25.381 31.316 24.99 31.707C24.795 31.902 24.539 32 24.283 32Z"
      fill="#DC0032"
    />
    <path
      d="M41.783 50H28.533C27.981 50 27.533 49.553 27.533 49C27.533 48.447 27.981 48 28.533 48H41.783C48.4 48 53.783 42.617 53.783 36C53.783 29.383 48.4 24 41.783 24H22.283C21.731 24 21.283 23.552 21.283 23C21.283 22.448 21.731 22 22.283 22H41.783C49.503 22 55.783 28.28 55.783 36C55.783 43.72 49.503 50 41.783 50Z"
      fill="#414042"
    />
    <path
      d="M28.216 56H14.216V42H28.216V56ZM16.216 54H26.216V44H16.216V54Z"
      fill="#DC0032"
    />
  </svg>
);
