import { typography } from '@hp/theme';
import React, { FC } from 'react';
import styled from 'styled-components';

const StrongStyled = styled.b<Pick<StrongProps, 'color'>>`
  color: ${({ color }) => color};
  font-weight: ${typography.fontWeight.normal};
`;

/* Not your ordinary weak props (delete comment later) */
type StrongProps = {
  className?: string;
  color?: string;
};

export const Strong: FC<StrongProps> = ({ className, color, children }) => (
  <StrongStyled className={className} color={color}>
    {children}
  </StrongStyled>
);
