import { Language } from '@hp/config';
import React, { useContext } from 'react';

const LanguageContext = React.createContext<LanguageContextProps>(null);

type LanguageContextProps = {
  language: Language;
  setLanguage: React.Dispatch<React.SetStateAction<Language>>;
};

export const LanguageProvider: React.FC<LanguageContextProps> = ({
  children,
  ...props
}) => {
  return (
    <LanguageContext.Provider value={{ ...props }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
