import { useI18n } from '@hp/locale';
import { meta, MetaRouteType } from '@hp/seo';
import { MessageDescriptor } from '@lingui/core';
import Head from 'next/head';
import React, { FC } from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  metaRoute?: MetaRouteType;
  title?: string | MessageDescriptor;
};

const LayoutWrapper = styled.main`
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-wrap: wrap;
`;

export const Layout: FC<Props> = ({
  className,
  metaRoute = 'default',
  children,
}) => {
  const i18n = useI18n();

  const metaTags = Object.entries(meta[metaRoute].tags).map(([key, value]) => (
    <meta name={key} content={i18n._(value.id)} key={key} />
  ));

  return (
    <LayoutWrapper className={className}>
      <Head>
        <title>{i18n._(meta[metaRoute].title.id)}</title>
        {metaTags}
      </Head>
      {children}
    </LayoutWrapper>
  );
};
