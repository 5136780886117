import { breakpoints, spacing } from '@hp/theme';
import styled, { css } from 'styled-components';

type FlexItemProps = {
  width?: [string | 'auto', string | 'auto'];
  flexGrow?: [number, number];
  marginLeft?: Extract<keyof typeof spacing, 'xs' | 's'>;
};

export type FlexBoxProps = {
  itemsPadding?: Exclude<keyof typeof spacing, 'unit'>;
  nowrap?: boolean;
};

export const FlexBox = styled.div<FlexBoxProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: ${({ nowrap }) => (nowrap ? 'nowrap' : 'wrap')};
  width: 100%;
  > * {
    margin: ${({ itemsPadding }) => spacing[itemsPadding || 'xs']} 0;
  }
`;

export const FlexItem = styled.div<FlexItemProps>`
  margin-left: ${({ marginLeft }) => marginLeft && spacing[marginLeft]};

  @media (max-width: ${breakpoints.maxMobile}) {
    width: ${({ width }) =>
      width && width[0] !== 'auto' ? `${width[0]}` : 'auto'};

    ${({ width, flexGrow = [1, 0] }) =>
      width && width[0] !== 'auto'
        ? ''
        : css`
            flex-grow: ${flexGrow[0]};
          `};
  }

  @media (min-width: ${breakpoints.minDesktop}) {
    width: ${({ width }) =>
      width && width[1] !== 'auto' ? `${width[1]}` : 'auto'};

    ${({ width, flexGrow = [0, 1] }) =>
      width && width[1] !== 'auto'
        ? ''
        : css`
            flex-grow: ${flexGrow[1]};
          `};
  }
`;
