import { breakpoints } from '@hp/theme';
import styled, { css } from 'styled-components';

type Column2Props = {
  hideOnMobile?: boolean;
  centered?: boolean;
};

export const Column2 = styled.div<Column2Props>`
  display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex')};
  flex-direction: column;
  flex: 50%;

  ${({ centered }) =>
    centered &&
    css`
      align-items: center;
      justify-content: center;
    `};

  @media (min-width: ${breakpoints.minDesktop}) {
    display: flex;
  }
`;

export const ColumnResponsive = styled.div`
  display: flex;
  flex-direction: row;
  flex: 100%;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.minDesktop}) {
    flex-direction: column;
    flex: 50%;
  }
`;
