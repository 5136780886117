import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
  backgroundColor?: string;
};

export const Corner: FC<Props> = ({
  className,
  color = colors.gray_warm,
  backgroundColor = colors.white,
}) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      d="M18 18L3.48096e-06 3.33739e-07L18 1.90735e-06L18 18Z"
      fill={backgroundColor}
    />
    <path d="M0 0L18 18H0V0Z" fill={color} />
  </svg>
);
