import React, { FC } from 'react';

import { LinkElement } from './styled';

type LinkProps = {
  className?: string;
  href?: string;
  target?: '_blank' | '_self';
  onClick?: (event: any) => void;
  disabled?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  testId?: string;
  noDecoration?: boolean;
};

export const Link: FC<LinkProps> = ({
  className,
  target = '_self',
  disabled = false,
  children,
  onClick,
  textAlign = 'left',
  testId,
  href,
  ...props
}) => (
  <LinkElement
    className={className}
    href={href || '#'}
    target={target}
    onClick={(event) => {
      onClick?.(event);
      if (!href) {
        event.preventDefault();
        return false;
      }
    }}
    disabled={disabled}
    textAlign={textAlign}
    {...props}
    data-test={testId}
  >
    {children}
  </LinkElement>
);
