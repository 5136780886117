import { breakpoints, elements, spacing } from '@hp/theme';
import styled, { css } from 'styled-components';

export const NarrowSection = styled.div`
  width: 100%;
  max-width: 350px;
`;

export const NarrowCenterSection = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${breakpoints.minDesktop}) {
    max-width: 560px;
  }
`;

export const InnerContentWrapper = styled.div`
  max-width: ${elements.innerElementMaxWidth};
  padding: 14px 20px;
  margin: auto;

  @media (min-width: ${breakpoints.minDesktop}) {
    padding: 0 40px;
  }
`;

type WideWrapperMobileProps = {
  marginSize?: Exclude<keyof typeof spacing, 'units'>;
  fullWidth?: boolean;
};

export const WideWrapperMobile = styled.div<WideWrapperMobileProps>`
  @media (max-width: ${breakpoints.maxMobile}) {
    margin-left: ${({ marginSize = 'm' }) => `-${spacing[marginSize]}`};
    margin-right: ${({ marginSize = 'm' }) => `-${spacing[marginSize]}`};

    ${({ fullWidth }) =>
      fullWidth
        ? css`
            width: 100vw;
          `
        : ''}
  }

  @media (min-width: ${breakpoints.maxMobile}) {
    width: 100%;
  }
`;
