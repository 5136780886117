import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const LocationIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="56"
    height="71"
    viewBox="0 0 56 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.0333 14L27.5167 1L5 14V40L19.3544 48.2875L27.4434 61.4268L35.4517 48.4187L50.0333 40V14Z"
        fill="white"
      />
    </g>
    <path
      d="M32.9683 25.2812C32.8365 25.3567 32.6297 25.3507 32.5011 25.2727L31.7435 24.831C31.6819 24.7936 31.6252 24.7336 31.5825 24.6615C31.58 24.6574 31.5775 24.6531 31.575 24.6488C31.5266 24.5714 31.4991 24.4898 31.4962 24.4148L31.477 23.5486C31.4713 23.4027 31.5695 23.2248 31.7018 23.149L40.8089 17.9453L28.0291 11.1139C27.8884 11.0373 27.7028 11.0003 27.517 11C27.331 10.9997 27.1451 11.0376 27.0042 11.1139L14.2243 17.9455L28.5411 26.1199C28.6738 26.191 28.7746 26.3599 28.7746 26.5124V38.4373C28.7746 38.587 28.6654 38.759 28.5317 38.829L27.7613 39.2487C27.6973 39.282 27.6158 39.2999 27.5307 39.2999C27.5259 39.2999 27.5209 39.2999 27.516 39.2999C27.4235 39.3021 27.3376 39.2841 27.2702 39.2487L26.4973 38.8288C26.3659 38.7603 26.2585 38.5875 26.2585 38.4373L26.2587 27.8068C26.2518 27.729 26.1937 27.634 26.13 27.5982L13.5167 20.3967V34.5023C13.5165 34.8179 13.7435 35.2073 14.0216 35.3669L27.0121 42.881C27.151 42.9609 27.3339 43.0006 27.5167 43C27.6996 42.9995 27.8825 42.962 28.0211 42.8812L41.0136 35.3669C41.2907 35.2057 41.517 34.8184 41.5167 34.5023V20.3968L32.9683 25.2812Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="55.0333"
        height="70.4268"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.513726 0 0 0 0 0.517647 0 0 0 0 0.517647 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="18.7551"
        y1="46.2566"
        x2="34.1645"
        y2="36.5472"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B80951" />
        <stop offset="1" stopColor="#ED1944" />
      </linearGradient>
    </defs>
  </svg>
);
