import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const PaymentCashIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2333 5.5V2.5C16.2333 1.94772 15.7856 1.5 15.2333 1.5H3.5C2.39543 1.5 1.5 2.39543 1.5 3.5V16.5C1.5 17.6046 2.39543 18.5 3.5 18.5H17.5C18.0523 18.5 18.5 18.0523 18.5 17.5V6.5C18.5 5.94772 18.0523 5.5 17.5 5.5H3.23333C2.27604 5.5 1.5 4.72396 1.5 3.76667V3.76667"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.7666 3.5H13.9666"
      stroke="#DC0032"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 9.5H15C13.8954 9.5 13 10.3954 13 11.5V11.5C13 12.6046 13.8954 13.5 15 13.5H18"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="15.5" cy="11.5" r="0.5" fill="#414042" />
  </svg>
);
