import React, { FC, useEffect, useState } from 'react';

type Props = {
  className?: string;
  withoutText?: boolean;
};

export const DPDLogo: FC<Props> = ({ className, withoutText }) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => setVisible(true), []);
  if (!visible) return null;

  const id = `SafariFixRandomId${Math.random()}`;

  return (
    <svg
      width={withoutText ? 32 : 73}
      height={32}
      className={className}
      viewBox={`0 0 ${withoutText ? 32 : 73} 32`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id={id}
          x1="5.23847"
          y1="35.2566"
          x2="20.6478"
          y2="25.5472"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A90034" />
          <stop offset="0.2717" stopColor="#BB0033" />
          <stop offset="0.7226" stopColor="#D30032" />
          <stop offset="1" stopColor="#DC0032" />
        </linearGradient>
      </defs>
      {!withoutText && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.391 24.1552C46.0132 24.5108 44.2182 24.6868 42.6578 24.6868C38.6523 24.6868 36 22.6083 36 18.8042C36 15.2039 38.4704 12.8717 42.086 12.8717C42.8919 12.8717 43.7497 12.9717 44.2703 13.2262V8H47.391V24.1552ZM44.2703 15.9137C43.7759 15.6856 43.126 15.5587 42.3446 15.5587C40.4474 15.5587 39.1726 16.7003 39.1726 18.7033C39.1726 20.8591 40.5506 22.0767 42.7615 22.0767C43.1518 22.0767 43.7497 22.0507 44.2703 21.9499V15.9137ZM68.2659 24.6868C69.8264 24.6868 71.6208 24.5108 73 24.1552H72.9998V8H69.8791V13.2262C69.3588 12.9717 68.5006 12.8717 67.6945 12.8717C64.0789 12.8717 61.6073 15.2039 61.6073 18.8042C61.6073 22.6083 64.2612 24.6868 68.2659 24.6868ZM69.8791 15.9137C69.3842 15.6856 68.734 15.5587 67.954 15.5587C66.0558 15.5587 64.7816 16.7003 64.7816 18.7033C64.7816 20.8591 66.1596 22.0767 68.3704 22.0767C68.7601 22.0767 69.3586 22.0507 69.8791 21.9499V15.9137ZM54.1699 15.6101C53.5458 15.6101 52.8186 15.6854 52.2988 15.8887V29H49.1513V13.8095C50.3992 13.3028 52.2987 12.8717 54.2227 12.8717C58.0201 12.8717 60.5945 14.9756 60.5945 18.5511C60.5945 22.4816 58.175 24.687 54.1446 24.687C54.1179 24.687 54.0914 24.6865 54.065 24.6861C54.039 24.6856 54.0132 24.6852 53.9876 24.6852V22.0228C55.9522 21.9959 57.4214 20.9546 57.4214 18.6521C57.4214 16.7002 56.0949 15.6101 54.1699 15.6101Z"
          fill="#414042"
        />
      )}
      <path
        d="M19.4516 14.2812C19.3198 14.3567 19.1131 14.3507 18.9844 14.2727L18.2269 13.831C18.1652 13.7936 18.1086 13.7336 18.0659 13.6615C18.0633 13.6574 18.0608 13.6531 18.0583 13.6488C18.0099 13.5714 17.9824 13.4898 17.9796 13.4148L17.9603 12.5486C17.9546 12.4027 18.0528 12.2248 18.1851 12.149L27.2922 6.94534L14.5125 0.113868C14.3717 0.0373003 14.1861 0.000330733 14.0003 2.11557e-06C13.8144 -0.000326502 13.6284 0.0376289 13.4875 0.113868L0.707631 6.94551L15.0244 15.1199C15.1571 15.191 15.258 15.3599 15.258 15.5124V27.4373C15.258 27.587 15.1487 27.759 15.015 27.829L14.2446 28.2487C14.1806 28.282 14.0992 28.2999 14.0141 28.2999C14.0092 28.2999 14.0042 28.2999 13.9993 28.2999C13.9069 28.3021 13.8209 28.2841 13.7536 28.2487L12.9806 27.8288C12.8493 27.7603 12.7419 27.5875 12.7419 27.4373L12.742 16.8068C12.7352 16.729 12.677 16.634 12.6134 16.5982L9.26671e-08 9.39667V23.5023C-0.000167434 23.8179 0.226831 24.2073 0.504924 24.3669L13.4954 31.881C13.6343 31.9609 13.8172 32.0006 14 32C14.1829 31.9995 14.3659 31.962 14.5044 31.8812L27.4969 24.3669C27.774 24.2057 28.0003 23.8184 28 23.5023V9.39683L19.4516 14.2812Z"
        fill={`url(#${id})`}
      />
    </svg>
  );
};
