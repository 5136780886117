import { spacing } from '@hp/theme';
import styled from 'styled-components';

export const Inner = styled.div<{
  paddingY?: keyof typeof spacing;
  paddingX?: keyof typeof spacing;
}>`
  padding: ${({ paddingY, paddingX }) =>
    `${paddingY ? spacing[paddingY] : 0} ${paddingX ? spacing[paddingX] : 0}`};
`;
