import { breakpoints, colors, misc, spacing } from '@hp/theme';
import styled from 'styled-components';

type PaperProps = {
  noShadow?: boolean;
  noBreadcrumbMargin?: boolean;
};

/** white block with shadow taking all horizontal space */
export const Paper = styled.div<PaperProps>`
  background: ${colors.white};
  position: relative;

  /* @keyframes animationFrames {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  } */

  @media (min-width: ${breakpoints.minDesktop}) {
    box-shadow: ${({ noShadow }) => (noShadow ? 'none' : misc.shadow_form)};
    margin-top: ${({ noBreadcrumbMargin }) => noBreadcrumbMargin && spacing.l};
    /* animation: animationFrames 0.3s; */
  }
`;
