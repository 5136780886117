import { RouteNameType, RouteQueryType } from '@hp/seo';
import { breakpoints, colors, typography } from '@hp/theme';
import React from 'react';
import styled from 'styled-components';

import { ChevronNavIcon } from './icons';
import { NextLink } from './NextLink';

type BreadcrumbNavigationProps<T extends RouteNameType> = {
  className?: string;
  goBackLink: T;
  testId?: string;
  query?: RouteQueryType<T>;
  children: React.ReactNode;
};

const ChevronNavIconStyled = styled(ChevronNavIcon)`
  transform: rotate(180deg);

  @media (min-width: ${breakpoints.minDesktop}) {
    transform: rotate(180deg);
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
`;

const NextLinkStyled = styled(NextLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0px;

  :hover {
    background: ${colors.gray_light};
    border-radius: 20px;
  }

  @media (min-width: ${breakpoints.minDesktop}) {
    :hover {
      background: ${colors.white};
      border-radius: 20px;
    }
  }
`;

const CurrentPage = styled.span`
  font-size: ${typography.fontSize.body};
  line-height: ${typography.lineHeight.body};
  font-weight: ${typography.fontWeight.light};
  padding-right: 40px;

  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: ${typography.fontSize.h2};
    line-height: ${typography.lineHeight.h2};
  }
`;

export const BreadcrumbNavigation: <T extends RouteNameType>(
  props: BreadcrumbNavigationProps<T>,
) => JSX.Element = ({ children, goBackLink, query, testId, className }) => {
  return (
    <Wrapper className={className}>
      <NextLinkStyled
        to={goBackLink}
        query={query}
        testId={testId}
        id={`gaButtonBack_breadcrumb_${goBackLink}`}
        className={`gaButton gaButtonBack_breadcrumb_${goBackLink}`}
      >
        <ChevronNavIconStyled color={colors.black} />
      </NextLinkStyled>

      <CurrentPage>{children}</CurrentPage>

      {/* Empty div for flexbox alignment */}
      <div />
    </Wrapper>
  );
};
