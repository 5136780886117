import { breakpoints } from '@hp/theme';
import styled from 'styled-components';

export const HideOnMobile = styled.div`
  display: none;
  width: 100%;

  @media (min-width: ${breakpoints.minDesktop}) {
    display: initial;
  }
`;

export const HideOnDesktop = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.minDesktop}) {
    display: none;
  }
`;
