import { breakpoints, colors } from '@hp/theme';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Typography, TypographyType } from './Typography';

type LabelValueProps = {
  className?: string;
  labelColor?: string;
  label: ReactNode;
};

const LabelValueWrapper = styled.div`
  flex: 100%;
  margin: 8px 0;

  @media (min-width: ${breakpoints.minDesktop}) {
    flex: 50%;
    margin: 16px 0;
  }
`;

export const LabelValue: FC<LabelValueProps> = ({
  className,
  label,
  children,
  labelColor = colors.black,
}) => {
  return (
    <LabelValueWrapper className={className}>
      <Typography type={TypographyType.BodyMicro} color={labelColor}>
        {label}
      </Typography>
      <div>
        <Typography type={TypographyType.BodyRegular} color={colors.black}>
          {children}
        </Typography>
      </div>
    </LabelValueWrapper>
  );
};
