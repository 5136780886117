import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const ErrorIcon: FC<Props> = ({ className }) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.96 5.33325H43.04L58.6667 20.9599V43.0399L43.04 58.6666H20.96L5.33337 43.0399V20.9599L20.96 5.33325Z"
      stroke="#DC0032"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32 21.3333V31.9999"
      stroke="#DC0032"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32 42.6667H32.0267"
      stroke="#DC0032"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
