import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const PaymentMobileIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 19.5H3.5C2.39543 19.5 1.5 18.6046 1.5 17.5V2.5C1.5 1.39543 2.39543 0.5 3.5 0.5H10.5C11.6046 0.5 12.5 1.39543 12.5 2.5V17.5C12.5 18.6046 11.6046 19.5 10.5 19.5H8.5"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 4.5H12.5"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 14.5H12.5"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 2.5H8.5"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="7" cy="17" r="1" fill="#414042" />
    <path
      d="M15.5 3C15.5 3 16.5 4.0405 16.5 5.50002C16.5 6.95954 15.5 8 15.5 8"
      stroke="#DC0032"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 2C17.5 2 18.5 3.4567 18.5 5.50003C18.5 7.54336 17.5 9 17.5 9"
      stroke="#DC0032"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
