import { Trans } from '@lingui/react';
import React from 'react';

export const labels = {
  switchLanguage: <Trans id="common.label.switchLanguage">Akuální jazyk</Trans>,
};

export const messages = {
  sectionInProgress: (
    <Trans id="common.messages.sectionInProgress">
      Chcete-li pokračovat, musíte změny použít nebo zrušit
    </Trans>
  ),
};
