import React, { FC } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  className?: string;
};

const StaticLoadingIcon: FC<Props> = ({ className }) => (
  <svg
    width="64"
    height="38"
    viewBox="0 0 64 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M45.7433 36.7365L63.1696 19.3101L45.7433 1.88379"
      stroke="#66A821"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3727 36.7365L40.799 19.3101L23.3727 1.88379"
      stroke="#66A821"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.4884 36.7365L18.9148 19.3101L1.4884 1.88379"
      stroke="#66A821"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const animation = css`
  @keyframes animationFrames {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  animation: animationFrames 1s linear alternate infinite none running;
`;

const AnimatedSvg = styled(StaticLoadingIcon)`
   >path {
    ${animation} 
   }
   >path:nth-child(3) {
    animation-delay: 0s;
   }
   >path:nth-child(2) {
    animation-delay: 0.3s;
   }
   >path:nth-child(1) {
    animation-delay: 0.6s;
   }
  }
`;

export const LoadingIcon = () => {
  return <AnimatedSvg />;
};
