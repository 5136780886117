import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const InfoIcon: FC<Props> = ({ className, color }) => (
  <svg
    className={className}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.351 18.3529C15.2835 17.5776 16.8873 16.1563 17.8894 14.331C18.8914 12.5058 19.2295 10.3896 18.8461 8.34297C18.4628 6.29637 17.3816 4.44601 15.7869 3.10716C14.1922 1.76832 12.1827 1.02384 10.1006 1.00056C8.01852 0.977287 5.9928 1.67666 4.36857 2.97952C2.74435 4.28237 1.62212 6.1081 1.1931 8.14562C0.764076 10.1831 1.05481 12.3064 2.01576 14.1536C2.97672 16.0008 4.54843 17.4576 6.4631 18.2759"
      stroke={color ? color : '#414042'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 14.5V7.5"
      stroke={color ? color : '#DC0032'}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 5.55C9.5 5.82614 9.72386 6.05 10 6.05C10.2761 6.05 10.5 5.82614 10.5 5.55H9.5ZM10.5 5.5C10.5 5.22386 10.2761 5 10 5C9.72386 5 9.5 5.22386 9.5 5.5H10.5ZM10.5 5.55V5.5H9.5V5.55H10.5Z"
      fill={color ? color : '#DC0032'}
    />
  </svg>
);
