import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const ParcelColorsSmallIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 5.5C1.5 4.94772 1.94772 4.5 2.5 4.5H21.5C22.0523 4.5 22.5 4.94772 22.5 5.5V19.5H1.5V5.5Z"
      stroke="#414042"
      strokeLinecap="round"
    />
    <path d="M8.5 5L8.5 19" stroke="#414042" strokeLinecap="round" />
    <path d="M15.5 5L15.5 19" stroke="#414042" strokeLinecap="round" />
    <rect
      x="10.5"
      y="7.5"
      width="3"
      height="5"
      stroke="#DC0032"
      strokeLinecap="round"
    />
    <path d="M2 9.5H8" stroke="#414042" strokeLinecap="round" />
    <path d="M2 14.5H8" stroke="#414042" strokeLinecap="round" />
    <path d="M16 9.5H22" stroke="#414042" strokeLinecap="round" />
    <path d="M16 14.5H22" stroke="#414042" strokeLinecap="round" />
    <path d="M10.5 14.5385H13.5" stroke="#DC0032" strokeLinecap="round" />
  </svg>
);
