import { RouteNameType, RouteQueryType, routes } from '@hp/seo';
import Link, { LinkProps } from 'next/link';
import React from 'react';

import { LinkElement } from './styled';

export type NextLinkType<T extends RouteNameType> = {
  to: T;
  asHrefParam?: any;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  textAlign?: 'left' | 'center' | 'right';
  onClick?: (event: Event) => void;
  testId?: string;
  id?: string;
  query?: RouteQueryType<T>;
  children: React.ReactNode;
} & Omit<LinkProps, 'href' | 'as'>;

export const NextLink = function <T extends RouteNameType>({
  className,
  children,
  disabled,
  to,
  textAlign = 'left',
  asHrefParam,
  testId,
  id,
  query,
}: NextLinkType<T>) {
  const rawHref = routes[to].href;

  //@ts-ignore
  const href = typeof rawHref === 'function' ? rawHref(query ?? {}) : rawHref;

  //when is specified query and exists asHref, we transform it to asHrefParam
  if (routes[to].asHref?.length === 1 && query && !asHrefParam) {
    asHrefParam = Object.values(query)[0];
  }

  return (
    <Link
      href={href}
      as={!!asHrefParam ? routes[to].asHref(asHrefParam) : undefined}
      passHref
    >
      <LinkElement
        id={id}
        className={className}
        disabled={disabled}
        textAlign={textAlign}
        noDecoration
        data-test={testId}
      >
        {children}
      </LinkElement>
    </Link>
  );
};
