import { breakpoints, colors, elements, spacing } from '@hp/theme';
import React from 'react';
import styled, { css } from 'styled-components';

import { Corner } from './icons/Corner';

export type NoteProps = {
  className?: string;
  marginSize?: Extract<keyof typeof spacing, 'm' | 'l'>;
  disablePaddingTop?: boolean;
};

const elementCornerSize = 18;

const NoteStyled = styled.div<
  Pick<NoteProps, 'marginSize' | 'disablePaddingTop'>
>`
  position: relative;
  padding: ${({ disablePaddingTop }) =>
      disablePaddingTop ? '1px' : `${spacing.m}`}
    24px ${spacing.m} ${spacing.m};
  ${({ marginSize }) =>
    marginSize
      ? css`
          margin-top: ${spacing[marginSize]};
          margin-bottom: ${spacing[marginSize]};
        `
      : ''};
  background: ${colors.note};
  border-radius: 0 ${elementCornerSize * 2}px 0 0;
  width: 100%;

  @media (min-width: ${breakpoints.minDesktop}) {
    max-width: ${elements.innerElementMaxWidth};
  }
`;

const CornerStyled = styled(Corner)`
  position: absolute;
  right: 0;
  top: 0;
`;

const CornerStyledOverlay1 = styled(Corner)`
  position: absolute;
  right: ${elementCornerSize}px;
  top: 0;
`;

const CornerStyledOverlay2 = styled(Corner)`
  position: absolute;
  right: 0;
  top: ${elementCornerSize}px;
`;

export const Note: React.FC<NoteProps> = ({
  children,
  marginSize,
  disablePaddingTop = true,
  ...props
}) => (
  <NoteStyled
    marginSize={marginSize}
    disablePaddingTop={disablePaddingTop}
    {...props}
  >
    {children}
    <CornerStyled backgroundColor="transparent" />
    <CornerStyledOverlay1 backgroundColor={colors.note} color="transparent" />
    <CornerStyledOverlay2 backgroundColor={colors.note} color="transparent" />
  </NoteStyled>
);

export default Note;
