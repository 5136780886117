import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const CheckCircleIcon: FC<Props> = ({
  className,
  color = colors.green,
}) => (
  <svg
    className={className}
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.6667 29.547V32.0003C58.6634 37.7507 56.8013 43.3461 53.3582 47.9518C49.9151 52.5576 45.0754 55.9269 39.5609 57.5574C34.0465 59.1878 28.1527 58.992 22.7586 56.9992C17.3645 55.0063 12.7591 51.3232 9.62921 46.4991C6.49936 41.675 5.01276 35.9685 5.39112 30.2305C5.76948 24.4925 7.99253 19.0305 11.7287 14.6592C15.4649 10.2878 20.5141 7.24131 26.1232 5.97401C31.7322 4.70672 37.6007 5.28652 42.8533 7.62696"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.6667 10.6665L32 37.3598L24 29.3598"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
